import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import PopularPosts from '../components/PopularPosts';
import CategoriesPosts from '../components/CategoriesPosts';
import InstagramSideWidget from '../components/InstagramSideWidget';
import FollowUsSocialSideWidget from '../components/FollowUsSocialSideWidget';
import NewsletterSieWidget from '../components/NewsletterSieWidget';
import TagsSideWidget from '../components/TagsSideWidget';
import TrendingPosts from '../components/TrendingPosts';
import RelatedPosts from '../components/RelatedPosts';
import Head from '../components/Head';
import { Context } from '../components/Context';
import * as moment from 'moment';
import 'moment/locale/ar';

var decode = require('unescape');

export const BlogPostTemplate = ({
  content,
  categories,
  tags,
  title,
  date,
  author,
  featured_media
}) => {
  let currentUrl = ''
  if (typeof window !== 'undefined'){
    currentUrl = window.location.href;
  }
  return (
    <Context.Consumer>
      {({ lang }) => (
      <main className="site-main">
          <div className="container-fluid no-left-padding no-right-padding page-content blog-single">
              <div className="container">
                  <div className="row">
                      <div className="col-xl-8 col-lg-8 col-md-6 col-12 content-area">
                          <article className="type-post">
                              <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: title }}></h1>
                              <div className="entry-cover">
                                  <img src={ featured_media ?  featured_media.source_url : "https://placehold.it/100x80"} alt="Post"/>
                              </div>
                              <div className="entry-content w-100">
                                  <div className="entry-header">
                                      <span className="post-category"><a href="#" title={decode(categories[0].name)}>{decode(categories[0].name)}</a></span>
                                      <div className="post-meta">
                                          {/* <span className="byline">by <a href="#" title="Indesign">{author.name}</a></span>&nbsp; */}
                                          <span className="post-date">{lang=='en' ? date : moment(date).format('MMMM DD, YYYY')}</span>
                                      </div>
                                  </div>

                                  <div className="w-100" dangerouslySetInnerHTML={{ __html: content }} />

                                  <div className="entry-footer">
                                      {tags && tags.length ? (
                                          <div className="tags">
                                                  {tags.map(tag => (
                                                      <Link key={`${tag.slug}tag`} to={`${lang}/tags/${decodeURI(tag.slug)}/`}>{tag.name}</Link>
                                                  ))}
                                          </div>
                                      ) : null}
                                      <ul className="social">
                                          <li className="share-label">{lang=='en' ? 'Share it on' : 'شاركه على'}</li>
                                          <li><a href={`${'https://www.facebook.com/sharer.php?u='+currentUrl}`} title="Facebook" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                          <li><a href={`${'https://twitter.com/intent/tweet?url='+currentUrl}`} title="Twitter"><i className="fa fa-twitter" target="_blank"></i></a></li>
                                          {/* <li><a href="#" title="Pinterest"><i className="fa fa-pinterest-p"></i></a></li> */}
                                      </ul>
                                  </div>
                              </div>
                          </article>
                          <RelatedPosts />
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 widget-area">
                          <PopularPosts />
                          <CategoriesPosts />
                          {/* <InstagramSideWidget /> */}
                          <FollowUsSocialSideWidget />
                          <NewsletterSieWidget />
                          <TagsSideWidget />
                          <TrendingPosts />
                      </div>
                  </div>
              </div>
          </div>
      </main>
      )}
    </Context.Consumer>
  );
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = ({ data }) => {
    const { wordpressPost: post } = data
    return (
        <Layout>
        <Head {...post.yoast} seoTitle={post.title}/>
        <BlogPostTemplate
            content={post.content}
            categories={post.categories}
            tags={post.tags}
            title={post.title}
            date={post.date}
            author={post.author}
            featured_media = {post.better_featured_image}
        />
        </Layout>
    )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      better_featured_image {
        source_url
      }
     tags {
        name
        slug
      }
      author {
        name
        slug
      }
      yoast {
        metadesc
        title
        opengraph_title
        opengraph_image
        opengraph_description
        metakeywords
        twitter_description
        twitter_image
        twitter_title
        canonical
      }
    }
  }
`

