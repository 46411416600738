import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import './all.scss';
import OwlCarousel from 'react-owl-carousel2';
import { Context } from './Context';

var decode = require('unescape');


export default class RelatedPosts extends React.Component {

    render() {
        
        return (
          <Context.Consumer>
            {({ lang }) => (
              <StaticQuery
                query={graphql`
                  query {
                    allWordpressPost {
                        edges {
                          node {
                            id
                            title
                            excerpt
                            better_featured_image {
                              source_url
                            }
                            categories {
                                name
                                slug
                            }
                            author {
                              name
                              slug
                              avatar_urls {
                                wordpress_48
                              }
                            }
                            date(formatString: "MMMM DD, YYYY")
                            slug
                            wordpress_site
                          }
                        }
                      }
                  }
                `}
                render={data => (
                    <div className="related-post">
                        <h3>{ lang=='en' ? 'You May Also Like' : 'ربما يعجبك أيضا'}</h3>
                        <div className="related-post-block">
                            <OwlCarousel options={{
                                responsive:{
                                    0:{
                                        items:1,
                                        nav: false
                                    },
                                    600:{
                                        items:3,
                                        nav: false
                                    },
                                    1000:{
                                        items:4,
                                        nav: false
                                    }
                                },
                                rewind: false,
                                autoplay: false,
                                margin: 15
                            }} >
                                {data.allWordpressPost.edges.filter(({node: post}) => post.wordpress_site===lang)
                                .map(({ node: post }, index) => (
                                  (index < 7 && (
                                    <div className="related-post-box" key={index}>
                                        <Link  to={post.wordpress_site + "/" + decodeURI(post.slug) + "/"}><img src={ post.better_featured_image ?  post.better_featured_image.source_url : "https://placehold.it/477x500"} alt="Post"/></Link>
                                        {/* {post.categories.map((category,index) => (
                                          <span className="related-posts-cat mr-2"><a href="#">{category.name}</a></span>
                                        ))} */}
                                        {post.categories && (
                                          <span><a href="#" title={decode(post.categories[0].name)}>{decode(post.categories[0].name)}</a></span>
                                        )}
                                        <h3><Link to={post.wordpress_site + "/" + decodeURI(post.slug) + "/"} title={post.title}><span dangerouslySetInnerHTML={{ __html: post.title }}></span></Link></h3>
                                    </div>
                                  ))
                                ))}
                            </OwlCarousel>
              </div>
                    </div>
                )}
              />
            )}
          </Context.Consumer>
        )
    }
}
